import React from 'react';
import $ from 'jquery';

import bugs from './bugs.json'
import bugs_northern from './bugs_northern.json'
import fish from './fish.json'
import fish_northern from './fish_northern.json'

export default class Data extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var entries = []
    var month = this.props.month
    var mode = this.props.mode

    var time = new Date()
    var hour = this.props.hour
    var minute = time.getMinutes()

    if (this.props.page === "fish"){
      if (this.props.hemisphere == "northern"){
        var file = fish_northern
      } else {
        var file = fish;
      }
    } else {
      if (this.props.hemisphere == "northern"){
        var file = bugs_northern
      } else {
        var file = bugs;
      }
    }

    file.map(entry => {
      var start = parseInt(entry.time[0][0].substring(0,2))
      var end = parseInt(entry.time[0][1].substring(0,2))

      if (start < end){
        var time = "normal"
      } else if (start > end) {
        var time = "exclude"
      } else {
        var time = "all"
      }
      
      if (entry.months.includes(month)) {
        if (mode == "now") {
          if ((time == "all") || (time == "normal" && hour >= start && (hour < end || (hour == end && minute == 0))) || (time == "exclude" && (hour >= start || hour < end || (hour == end && minute == 0)))) {
            entries.push(entry)
          }
        } else if (mode == "later") {
          entries.push(entry)
        }
      }
    });
    

    return (
      <div className="info-list-container">
        <div className="content">
        {this.props.mode == "now" ? this.props.page == "fish" ? "Here is a list of fish available now!" : "Here is a list of bugs available now!" : this.props.page == "fish" ? "Here is a list of fish available this season!" : "Here is a list of bugs available this season!"}
        </div>
        <div className="info-list title">
          <h2 className="image"></h2>
          {this.props.page == "fish" ? <h2>Fish</h2> : <h2>Bug</h2>}
          <h2>Location</h2>
          {this.props.page === "fish" ? <h2>Shadow</h2> : ""}
          <h2 className="price">Price</h2>
        </div>
        {entries.map(entry => {
          return(
          <div className="info-list entry">
            <div className="image">
              <img src={entry.image}></img>
            </div>
            <div>
              {entry.name}
            </div>
            <div>
              {entry.location}
            </div>
            {this.props.page === "fish" ? <div>
              {entry.shadow_size}
            </div> : ""}
            <div className="price">
              {entry.price}
            </div>
          </div>
          ) 
        })}
      </div>
    );
  }

}
