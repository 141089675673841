import React from 'react';
import { FaHome, FaFish, FaBug, FaBirthdayCake } from 'react-icons/fa';


export default function NavigationBar() {
  return (
    <div className="nav-bar-container">
    <a href={`${process.env.PUBLIC_URL}/`} className="item home">
        <h1><FaHome /></h1>
        <h1 className="title">Home</h1>
    </a>
    <a href={`${process.env.PUBLIC_URL}/villagers`} className="item">
        <h1><FaBirthdayCake /></h1>
        <h1 className="title">Birthdays</h1>
    </a>
    <a href={`${process.env.PUBLIC_URL}/fish`} className="item">
        <h1><FaFish /></h1>
        <h1 className="title">Fish</h1>
    </a>
    <a href={`${process.env.PUBLIC_URL}/bugs`} className="item">
        <h1><FaBug /></h1>
        <h1 className="title">Bugs</h1>
    </a>
    </div>
  );
}
