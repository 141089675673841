import React from 'react';
import NavigationBar from '../includes/js/navigation-bar'
import villagers from '../pages/files/villagers.json'
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io'


export default class Villagers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cur_month: ''
    }

    this.changeMonth = this.changeMonth.bind(this)
  }

  componentDidMount(){
    var month = ['January','February','March','April','May','June','July','August','September','October','November','December']
    var today = new Date()
    var month = month[today.getMonth()]

    this.setState({
      cur_month: month
    })
  }

  changeMonth(direction){
    var month = ['January','February','March','April','May','June','July','August','September','October','November','December']
    var cur = month.indexOf(this.state.cur_month)
    // console.log(cur)
    if(direction > 0){
      cur += 1
    } else {
      cur -= 1
    }
    cur %= 12
    // console.log(cur)
    this.setState({
      cur_month: month[cur]
    })

    this.forceUpdate()
  }

  render() {

    var month_arr = ['January','February','March','April','May','June','July','August','September','October','November','December']
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    var today = new Date()
    if(typeof this.state.cur_month == "undefined"){
      var month = month_arr[today.getMonth()]
    } else {
      var month = this.state.cur_month
      console.log("HSIAD")
    }

    console.log(month)
    const cal_rows = []
    const cal_cols = []
    for(var i=0; i < 7; i++){
      cal_cols.push(i)
    }
    for(var i=0; i < 7; i++){
      cal_rows.push(i)
    }

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), month_arr.indexOf(month), 1);
    
    firstDay = firstDay.toString().split(' ')[0]
    var noDays = new Date(date.getFullYear(), month_arr.indexOf(month)+1, 0).getDate()
    var test = new Date(date.getFullYear(), 3, 0).getDate() +1
    console.log("days in " + month + " is " + noDays)
    console.log("days in April is " + test)
    var dates = 1;

    var birthdays = {}
    var pictures = {}
    villagers.map(entry =>{
      var birthdayMonth = entry.birthday.split(' ')[0]
      var day = entry.birthday.split(' ')[1].slice(0,-2)

      if(month == birthdayMonth){
        if (day in birthdays){
          birthdays[day].push(entry.name)
          pictures[day].push(entry.birthday)
        } else {
          birthdays[day] = [entry.name]
          pictures[day] = [entry.image]
        }
      }
    })

    return (
      <div>
        <NavigationBar />
        <div className="page-container">
          <h1>Villager Birthdays</h1>
          <div className='calendar-month'>
            <h1 onClick={()=>this.changeMonth(-1)}className="button"><IoIosArrowBack/></h1><h1 className='month'> {month}</h1><h1 onClick={()=>this.changeMonth(1)} className="button"><IoIosArrowForward/></h1>
          </div>
          <div className='calendar-container'>
              {cal_rows.map(entry => {return(
                dates <= noDays ? 
                <div className={`calendar-row ${entry == 0 ? `headings` : ``}`}>
                  {cal_cols.map(entry_col => {return(
                    entry == 0 ? <div className={`day cell`}>{days[entry_col]}</div>:<div className={`${days[entry_col].startsWith(firstDay) && dates == 1? dates : (dates == 1? 'X' : (dates + 1 <= noDays + 1 ? dates : dates++ + ` X` ))} cell`}><div className="date">{days[entry_col].startsWith(firstDay) && dates == 1? dates++ : (dates == 1? '' : (dates + 1 <= noDays + 1 ? dates ++ : ''))}</div><div className='names'>{dates-1 in birthdays ? birthdays[dates-1].map(name => {return(<div>{name}</div>)}) : ""}</div></div>
                  )})}
                </div>: ''
              )})}
           
          </div>
        </div>
      </div>
    );
  }
        

}
