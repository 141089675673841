import React from 'react';

import NavigationBar from '../includes/js/navigation-bar'
import Data from './files/data'
import Search from './files/search'
import { FaHome, FaLeaf } from 'react-icons/fa';
import { FiClock, FiSearch, FiSun } from 'react-icons/fi';
import { TiMediaPlayOutline } from 'react-icons/ti'

export default class FishPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            hemisphere: 'southern',
            current_tab: 'now',
            season: '',
            time: '',
            date: '',
            month: '',
            hour: '',
        }

        this.handleCurrentChange = this.handleCurrentChange.bind(this);
        this.handleHemisphereChange = this.handleHemisphereChange.bind(this);
        this.setSeason = this.setSeason.bind(this);
        this.setTime = this.setTime.bind(this);
    }

    componentDidMount(){
        let time = new Date()
        var day = time.getDate()
        var month = time.getMonth()
        var year = time.getFullYear()
        var hours = time.getUTCHours()
        var hemisphere = this.props.hemisphere;
        // time = time.getHours()
        this.setSeason('southern')

        this.setState({
            date: day +  " " + month + " " + year,
            month: month+1,
            hour: time.getHours(),
            hemisphere: hemisphere,
        })

    }

    handleCurrentChange(tab){
        this.setState((state) => ({
            current_tab: tab
        }));
    }

    handleHemisphereChange(hemisphere){
        this.setState({
            hemisphere: hemisphere
        })

        this.setSeason(hemisphere)
    }

    setSeason(hemisphere){
        let time = new Date()
        var month = time.getMonth()
        if (month >= 2 && month <= 4) {
            if(hemisphere == "northern"){
                var season = "Spring"
            } else {
                var season = "Autumn";
            }
        } else if (month >= 5 && month <= 7) {
            if(hemisphere == "northern"){
                var season = "Summer"
            } else {
                var season = "Winter";
            }
        } else if (month >= 8 && month <= 10){
            if(hemisphere == "northern"){
                var season = "Fall"
            } else {
                var season = "Spring";
            }
        } else {
            if(hemisphere == "northern"){
                var season = "Winter"
            } else {
                var season = "Summer";
            }
        }

        this.setState({
            season: season
        })
    }

    setTime(toggle){
        var newTime = new Date()
        var display_hour = parseInt(document.getElementById('set-time').value)
        var display_am = document.getElementById('set-am').value

        if(toggle == 'AM'){
            if(this.state.hour > 12){
                var hour = this.state.hour - 12
            } else {
                var hour = this.state.hour + 12
            }
        } else {
            if(display_am === "AM"){
                var hour = display_hour
            } else {
                var hour = display_hour + 12
            }
        }

        hour = hour % 24
        newTime.setHours(hour)

        this.setState({
            hour: newTime.getHours()
        })

    }

    render(){

        return (
            <div>
                <NavigationBar />
                <div className="page-container">
                    {this.props.page == "fish" ? <h1>List of fish</h1> : <h1>List of bugs</h1>}
                    <div className="hemisphere-toggle">
                        <div className={this.state.hemisphere=="northern" ? `` : `active`} onClick={() => this.handleHemisphereChange("southern")}>
                            Southern
                        </div>
                        <div className={this.state.hemisphere=="northern" ? `active` : ``} onClick={() => this.handleHemisphereChange("northern")}>
                            Northern
                        </div>
                    </div>
                    <div className="info-container">
                        <div className="item">
                            <h1><FaLeaf /></h1>
                            <h1>{this.state.season}</h1>
                            <h2><FaLeaf /></h2>
                            <h2>{this.state.season}</h2>
                        </div>
                        <div className="item" editable="true">
                            <h1><FiClock /></h1>
                            <div className="time-container">
                            <input id="set-time" type="number" min="1" max="12" onKeyDown={(e) => e.preventDefault()} onChange={() => this.setTime('hour')} defaultValue={this.state.hour > 12 ? (this.state.hour-12): this.state.hour}/>
                            <h1 id="set-am" onClick={() => this.setTime('AM')}>{this.state.hour >= 12 &&  this.state.hour != 0 ? "PM": "AM"}</h1>
                            </div>
                            <h2><FiClock /></h2>
                            <h2>{this.state.hour > 12 ? (this.state.hour-12): this.state.hour}</h2>
                            <h2>{this.state.hour >= 12 &&  this.state.hour != 0 ? "PM": "AM"}</h2>
                        </div>
                    </div>
                    <div className="tabs">
                        <div className={`item ${this.state.current_tab === 'now' ? 'active' : ''}`} onClick={() => this.handleCurrentChange('now')}>
                            <h2><TiMediaPlayOutline /></h2>
                            <h2 className="title">Now</h2>
                        </div>
                        <div className={`item ${this.state.current_tab === 'later' ? 'active' : ''}`} onClick={() => this.handleCurrentChange('later')}>
                            <h2><FiSun /></h2>
                            <h2 className="title">Later</h2>
                        </div>
                        <div className={`item ${this.state.current_tab === 'search' ? 'active' : ''}`} onClick={() => this.handleCurrentChange('search')}>
                            <h2><FiSearch /></h2>
                            <h2 className="title">Search</h2>
                        </div>
                    </div>
                    <div>
                        {this.state.current_tab==="now" &&
                        <Data month={this.state.month} mode="now" page={this.props.page} hemisphere={this.state.hemisphere} hour={this.state.hour}/>}
                        {this.state.current_tab==="later" &&
                        <Data month={this.state.month} mode="later" page={this.props.page} hemisphere={this.state.hemisphere} hour={this.state.hour}/>}
                        {this.state.current_tab==="search" &&
                        <Search mode="search" page={this.props.page} hemisphere={this.state.hemisphere} hour={this.state.hour}/>}
                    </div>
                </div>
            </div>
          );
    }
  
}
