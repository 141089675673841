import React from 'react';

import NavigationBar from '../includes/js/navigation-bar'

import Hero from '../includes/files/hero.jpg'

export default class HomePage extends React.Component{
    render(){
        return (
            <div className>
                <NavigationBar />
                <div className="page-container home">
                    <h1>Hello and welcome!</h1>
                    <h2>This site was just a simple project which would help me figure out which fish/bugs I can catch at which moment.</h2>
                    
                    <h2>All data has been scraped from the <a href="https://animalcrossing.fandom.com/wiki/Fish_(New_Horizons)#Southern%20Hemisphere">Animal Crossing Wiki</a> and is currently set for the Southern Hemisphere.</h2>

                    <h2>It's currently a work in progress so any feedback will be appreciated!</h2>
                </div>
            </div>
          );
    }
  
}
