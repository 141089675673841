import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, browserHistory, HashRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import Home from './pages/home'
import Data from './pages/DataPage'
import Villagers from './pages/VillagersPage'

import './includes/css/main.scss'


ReactDOM.render(
  <React.StrictMode>
    
     <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/fish" exact component={() => <Data page="fish"/>}/>
          <Route path="/bugs" exact component={() => <Data page="bugs"/>}/>
          <Route path="/villagers" exact component={Villagers}/>
        </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
