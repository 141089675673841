import React from 'react';
import $ from 'jquery';

import file from '../../includes/files/fish'
import file2 from './fish.json'

export default class Data extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="info-list-container">
        <div className="content">
          Coming soon!

          This feature will let you search which times are best suited to catch a fish/bug of your choosing!
        </div>
      </div>
    );
  }

}
